import { RewardsExtension } from '/~/core/extension'

class ScheduledPaymentsExtension extends RewardsExtension {
  setRoutes() {
    return [
      {
        path: '/scheduled-payments',
        component: () => import('/~/extensions/scheduled-payments/root.vue'),
        meta: {
          parentRoute: 'payments',
        },
        children: [
          {
            path: ':type?',
            name: 'scheduled-payments-list',
            component: () =>
              import('/~/extensions/scheduled-payments/views/list.vue'),
            meta: {
              title: 'Scheduled payments',
            },
            props: true,
          },
        ],
      },
    ]
  }
}

export default ScheduledPaymentsExtension
